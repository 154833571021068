import React from "react";
import {Link} from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found"/>
        <div className="not-found">
            <h1>This page does not exist</h1>
            <Link to="/">Go back</Link>
        </div>
    </Layout>
)

export default NotFoundPage
